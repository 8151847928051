<template>
  <div>
    <b-card-code title="Add Offer" :before-change="validationForm">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col cols="12">
              <b-row
                v-for="(item, index) in offerData.translations"
                :id="item.id"
                :key="item.id"
                ref="row"
              >
                <!-- translation language -->
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="language"
                    rules="required"
                  >
                    <b-form-group
                      label="Language"
                      label-for="language"
                      :state="errors.length > 0 ? false : null"
                    >
                      <v-select
                        :id="'language' + index"
                        v-model="tranLocaleSelect[index]"
                        :disabled="tranLocaleSelect[index].disa"
                        :state="tranLocaleSelect[index] === null ? false : true"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="localeOptions"
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        label="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- translation name -->
                <b-col md="6">
                  <b-form-group label="Name" label-for="name">
                    <validation-provider
                      #default="{ errors }"
                      name="name"
                      rules="required"
                    >
                      <b-form-input
                        :id="'TranslationName' + index"
                        v-model="offerData.translations[index].name"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- translation description -->
                <b-col cols="12">
                  <b-form-group label="Description" label-for="description">
                    <validation-provider
                      #default="{ errors }"
                      name="description"
                      rules="required"
                    >
                      <vue-editor
                        :id="'translationDescription' + index"
                        v-model="offerData.translations[index].description"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Remove Button -->
                <b-col lg="2" md="3" class="mb-50">
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    v-show="!tranLocaleSelect[index].disa"
                    class="mt-0 mt-md-2"
                    @click="removeItem(index)"
                  >
                    <feather-icon icon="XIcon" class="mr-25" />
                    <span>Remove</span>
                  </b-button>
                </b-col>
                <b-col cols="12">
                  <hr />
                </b-col>
              </b-row>
            </b-col>
            <!-- add new button -->
            <b-col sm="2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                block
                @click="repeateAgain"
              >
                <feather-icon icon="PlusIcon" class="mr-25" />
                <span>Add New</span>
              </b-button>
            </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="type"
                rules="required"
              >
                <b-form-group
                  label="Type"
                  label-for="type"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="type"
                    v-model="typeSelect"
                    :state="typeSelect == null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="typeOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group label="Amount" label-for="amount">
                <validation-provider
                  #default="{ errors }"
                  name="amount"
                  rules="required|regex:\b\d+(?:\.\d\d?)?\b"
                >
                  <b-form-input
                    min="1"
                    id="amount"
                    type="number"
                    v-model="offerData.amount"
                    :state="errors.length > 0 ? false : null"
                    placeholder="enter amount"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="User Numbers" label-for="User-Numbers">
                <validation-provider
                  #default="{ errors }"
                  name="user numbers"
                  rules="required|integer"
                >
                  <b-form-input
                    min="1"
                    id="User-Numbers"
                    type="number"
                    v-model="offerData.usernumbers"
                    :state="errors.length > 0 ? false : null"
                    placeholder="enter user numbers"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="start date"
                rules="required"
              >
                <b-form-group
                  label="Start Date"
                  label-for="start date"
                  :state="errors.length > 0 ? false : null"
                >
                  <flat-pickr
                    v-model="offerData.startdate"
                    class="form-control"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="end date"
                rules="required"
              >
                <b-form-group
                  label="End date"
                  label-for="end date"
                  :state="errors.length > 0 ? false : null"
                >
                  <flat-pickr
                    v-model="offerData.enddate"
                    class="form-control"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <b-button variant="primary" @click.prevent="validationForm"
                >Submit</b-button
              >
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import BCardCode from '@core/components/b-card-code'
import { VueEditor } from 'vue2-editor'
import { heightTransition } from '@core/mixins/ui/transition'
import { required, integer } from '@validations'
export default {
  components: {
   
    BCardCode,
    VueEditor,
    flatPickr,
  },
  mixins: [heightTransition],
  data() {
    return {
      errors_back: [],
      showDismissibleAlert: false,
      required,
      integer,
      offerData: {
        startdate: '',
        enddate: '',
        amount: '',
        type: '',
        usernumbers: '',
        translations: [
          {
            locale: '',
            name: '',
            description: '',
          },
          {
            locale: '',
            name: '',
            description: '',
          },
        ],
      },
      tranLocaleSelect: [
        {
          value: 'en',
          text: 'English',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
      ],
      localeOptions: [
        {
          value: 'en',
          text: 'English',
        },
        {
          value: 'ar',
          text: 'Arabic',
        },
      ],
      typeOptions: [
        {
          text: 'Fixed Price',
          value: 'fixed_price',
        },
        {
          text: 'Percentage',
          value: 'percentage',
        },
      ],
      typeSelect: null,
    }
  },
  methods: {
    // submit offer API
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          for (let trn in this.offerData.translations) {
            this.offerData.translations[trn].locale = this.tranLocaleSelect[
              trn
            ].value
          }
          this.offerData.type = this.typeSelect.value
          axios
            .post('offers', this.offerData)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.push('/Offer/Index')
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    removeItem(index) {
      if (this.offerData.translations.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        this.offerData.translations.splice(index, 1)
        this.tranLocaleSelect.splice(index, 1)
      }
    },
    repeateAgain() {
      this.offerData.translations.push({})
      this.tranLocaleSelect.push({})
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>
// REPETED FORM
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
